<template>
    <footer class="footer">
        <div class="footer-primary">
            <div class="footer-primary__contents">
                <ul class="footer-primary__link">
                    <li class="footer-primary__link-list">
                        <a class="btn" href="https://new.sba.kr/" target="_blank">서울산업진흥원</a>
                    </li>
                    <li class="footer-primary__link-list">
                        <a class="btn" href="https://smc.sba.kr/" target="_blank">{{$t("common.SBA 국제유통센터")}}</a>
                    </li>
                    <li class="footer-primary__link-list">
                        <a class="btn" href="/privacy" target="_blank">{{$t("common.개인정보처리방침")}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer-secondary">
            <div class="footer-secondary__contents">
                <div class="footer-secondary__row">
                    <p class="color-primary">{{ $t("common.SBA 국제유통센터") }}</p>
                    <ul class="footer-secondary__info">
                       <li><b>주소</b> 07563 서울시 강서구 공항대로61길 29 SBA 국제유통센터</li>
                       <li><b>사업자등록번호</b> 102-82-09623</li>
                       <li><b>이메일</b> smc.b2bmatching@sba.kr</li>
                    </ul>
                    <p class="color-primary">와이즈플래닛컴퍼니(운영사)</p>
                    <ul class="footer-secondary__info">
                       <li><b>주소</b> 08513 서울시 금천구 벚꽃로 244, 벽산디지털밸리5차 905, 906호</li>
                       <li><b>사업자등록번호</b> 113-86-71071</li>
                       <li><b>이메일</b> wp_marketing@wplanet.co.kr</li>
                    </ul>
                    <img class="footer-secondary__logo" src="/images/i-seoul-u.png" alt="I SEOUL U">
                </div>
                <!-- <div class="footer-secondary__row">
                    <p class="tit">Contact</p>
                    <ul class="footer-secondary__contact">
                        <li><b>시스템문의</b> 02-2657-5804</li>
                        <li><b>서울어워드</b> 02-2657-5812, 02-2657-5804</li>
                        <li><b>온라인판로</b> 02-2657-5810, 02-2657-5813</li>
                    </ul>
                    <p>(C) 2021. SBA국제유통센터. all rights reserved.</p>
                </div> -->
            </div>
        </div>

        <div class="quick-menu">
            <v-btn depressed rounded href="/mypage/question" color="primary">
                <span>{{$t("common.관리자 문의")}}</span>
                <img src="/images/icon/contact.png" alt="">
            </v-btn>
        </div>
    </footer>
</template>

<script>
export default {
    data(){
        return {
            links:[
                { text: '서울산업진흥원', href: 'https://new.sba.kr/' },
                { text: 'SBA국제유통센터', href: 'https://smc.sba.kr/' },
                { text: '개인정보처리방침', href: '/privacy' },
            ]
        }
    },
    mounted(){
    },
    methods: {
    }
}
</script>
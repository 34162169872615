var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-primary"
  }, [_c('div', {
    staticClass: "footer-primary__contents"
  }, [_c('ul', {
    staticClass: "footer-primary__link"
  }, [_vm._m(0), _c('li', {
    staticClass: "footer-primary__link-list"
  }, [_c('a', {
    staticClass: "btn",
    attrs: {
      "href": "https://smc.sba.kr/",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.SBA 국제유통센터")))])]), _c('li', {
    staticClass: "footer-primary__link-list"
  }, [_c('a', {
    staticClass: "btn",
    attrs: {
      "href": "/privacy",
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.개인정보처리방침")))])])])])]), _c('div', {
    staticClass: "footer-secondary"
  }, [_c('div', {
    staticClass: "footer-secondary__contents"
  }, [_c('div', {
    staticClass: "footer-secondary__row"
  }, [_c('p', {
    staticClass: "color-primary"
  }, [_vm._v(_vm._s(_vm.$t("common.SBA 국제유통센터")))]), _vm._m(1), _c('p', {
    staticClass: "color-primary"
  }, [_vm._v("와이즈플래닛컴퍼니(운영사)")]), _vm._m(2), _c('img', {
    staticClass: "footer-secondary__logo",
    attrs: {
      "src": "/images/i-seoul-u.png",
      "alt": "I SEOUL U"
    }
  })])])]), _c('div', {
    staticClass: "quick-menu"
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "href": "/mypage/question",
      "color": "primary"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.관리자 문의")))]), _c('img', {
    attrs: {
      "src": "/images/icon/contact.png",
      "alt": ""
    }
  })])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "footer-primary__link-list"
  }, [_c('a', {
    staticClass: "btn",
    attrs: {
      "href": "https://new.sba.kr/",
      "target": "_blank"
    }
  }, [_vm._v("서울산업진흥원")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "footer-secondary__info"
  }, [_c('li', [_c('b', [_vm._v("주소")]), _vm._v(" 07563 서울시 강서구 공항대로61길 29 SBA 국제유통센터")]), _c('li', [_c('b', [_vm._v("사업자등록번호")]), _vm._v(" 102-82-09623")]), _c('li', [_c('b', [_vm._v("이메일")]), _vm._v(" smc.b2bmatching@sba.kr")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "footer-secondary__info"
  }, [_c('li', [_c('b', [_vm._v("주소")]), _vm._v(" 08513 서울시 금천구 벚꽃로 244, 벽산디지털밸리5차 905, 906호")]), _c('li', [_c('b', [_vm._v("사업자등록번호")]), _vm._v(" 113-86-71071")]), _c('li', [_c('b', [_vm._v("이메일")]), _vm._v(" wp_marketing@wplanet.co.kr")])]);

}]

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--join"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "inner"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_c('div', {
    staticClass: "join-gate"
  }, [_c('div', {
    staticClass: "join-gate-box join-gate-box--personal"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("일반회원")]), _c('p', [_vm._v(" 회원에 가입하시면 신작소개 및 이벤트 정보를 받아볼수 있습니다. ")]), _c('i', {
    staticClass: "icon icon-normal"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit('normal');
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)]), _c('div', {
    staticClass: "join-gate-box join-gate-box--business"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("아티스트회원")]), _c('p', [_vm._v(" 작품을 민팅하신 작가님만 회원가입이 가능합니다. ")]), _c('i', {
    staticClass: "icon icon-artist"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submit('author');
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
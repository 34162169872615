export function Header(){
    const body = document.querySelector('#body');
    const header = document.querySelector('header');
    const fluidTitle = document.querySelector('#fluid-title');
    const menu = document.querySelector('#menu');
    const menuLink = menu.querySelectorAll('#menu a');
    const container = document.querySelector('#container');
    const photoItem = document.querySelectorAll('.photo-item');

    for(var i=0; i<menuLink.length; i++){
        menuLink[i].addEventListener('click', menuClick);
    }
    for(var i=0; i<photoItem.length; i++){
        photoItem[i].addEventListener('click', menuClick);
    }
    fluidTitle.addEventListener('click', menuClick);

    // menu link click
    function menuClick() {
        body.classList.add('is-loading');
        setTimeout(() => {
            body.classList.remove('is-loading');
        }, 1000);

        menu.classList.remove('visible');
        setTimeout(() => {
            menu.classList.add('visible');
        }, 1700);
        menuSetting(containerSetting);
    }

    // 창크기변경시
    window.onresize = function (event) {
        menuSetting(containerSetting);
    }

    // menu top
    function menuSetting(callback) {
        setTimeout(() => {
            var menuHeight = menu.offsetHeight;
            if (body.classList.contains('headerTop') == true) {
                header.style.top = menuHeight + 'px';
            } else if (body.classList.contains('headerLogoNone') == true) {
                header.style.top = menuHeight + 'px';
            }else {
                header.style.top = null;
                header.style.bottom = null;
            }
            callback();
        }, 500);
    } menuSetting(containerSetting);

    // page-content margin-top
    function containerSetting() {
        setTimeout(() => {
            var headerHeight = header.offsetHeight;
            var menuHeight = menu.offsetHeight;
            if (body.classList.contains('headerTop') == true) {
                container.style.marginTop = headerHeight + menuHeight + 20 + 'px';
                container.style.marginBottom = null;
            } else if (body.classList.contains('headerTopSm') == true) {
                container.style.marginTop = headerHeight + menuHeight + 'px';
                container.style.marginBottom = null;
            } else if (body.classList.contains('headerBottom') == true) {
                container.style.marginTop = null;
                container.style.marginBottom = headerHeight + menuHeight + 'px';
            } else if (body.classList.contains('headerLogoNone') == true) {
                container.style.marginTop = headerHeight + menuHeight + 20 + 'px';
                container.style.marginBottom = null;
            } else {
                container.style.marginBottom = null;
            }
        }, 1200);
    }
}

<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="inner">
                <h2 class="join-title">회원가입</h2>

				<div class="join-container">
					<!-- 가입 단계 표시 -->
					<nav class="join-step">
						<ul class="join-step-lists">
							<li class="list">
								<span class="badge">1</span>
								<span class="text">약관동의</span>
							</li>
							<li class="list list--on">
								<span class="badge">2</span>
								<span class="text">정보입력</span>
							</li>
							<li class="list">
								<span class="badge">3</span>
								<span class="text">가입완료</span>
							</li>
						</ul>
					</nav>

                    <join-form :code="code" :skin="code"></join-form>
					
				</div>
			</div>
		</main>
		
		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import JoinForm from "@/components/client/join/join-form.vue";

export default {
    props: ["code"],

	components: {
		MainHeader,
		MainFooter,
        JoinForm,
	},
}
</script>

<template>
<header>
        <h1>DESIGN BIDAM</h1>

        <router-link id="fluid-title" to="/" >
            <div class="letters" @click="$route.path == '/' ? $emit('intro') : null">
                <img src="/images/logo.svg" alt="DESIGN BIDAM">
            </div>
        </router-link>

        <div id="menu" class="visible">
            <nav id="primary-nav">
                <ul>
                    <router-link to="/about" v-slot="{ href, navigate, isActive, isExactActive }">
                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                            <span>
                                <a :href="href" @click="navigate">
                                    About
                                </a>
                                <router-link to="/" class="primary-nav__back"></router-link>
                            </span>
                        </li>
                    </router-link>
                    <router-link to="/archive" v-slot="{ href, navigate, isActive, isExactActive }">
                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                            <span>
                                <a :href="href" @click="navigate">
                                    Archive
                                </a>
                                <router-link to="/" class="primary-nav__back"></router-link>
                            </span>
                            <div class="primary-nav__child">
                                <ul>
                                    <router-link to="/archive/Residence" v-slot="{ href, navigate, isActive, isExactActive }">
                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                            <span>
                                                <a :href="href" @click="navigate">
                                                    Residence
                                                </a>
                                                <router-link to="/archive" class="primary-nav__back"></router-link>
                                            </span>
                                            <div class="primary-nav__child">
                                                <ul>
                                                    <router-link to="/archive/Residence" v-slot="{ href, navigate, isActive, isExactActive }">
                                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                                            <span>
                                                                <a :href="href" @click="navigate">
                                                                    <span class="ellip" v-if="board">{{ board.subject }}</span>
                                                                </a>
                                                                <router-link to="/archive/Residence" class="primary-nav__back"></router-link>
                                                            </span>
                                                        </li>
                                                    </router-link>
                                                </ul>
                                            </div>
                                        </li>
                                    </router-link>
                                    <router-link to="/archive/Retail" v-slot="{ href, navigate, isActive, isExactActive }">
                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                            <span>
                                                <a :href="href" @click="navigate">
                                                    Retail
                                                </a>
                                                <router-link to="/archive" class="primary-nav__back"></router-link>
                                            </span>
                                            <div class="primary-nav__child">
                                                <ul>
                                                    <router-link to="/archive/Retail" v-slot="{ href, navigate, isActive, isExactActive }">
                                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                                            <span>
                                                                <a :href="href" @click="navigate">
                                                                    <span class="ellip" v-if="board">{{ board.subject }}</span>
                                                                </a>
                                                                <router-link to="/archive/Retail" class="primary-nav__back"></router-link>
                                                            </span>
                                                        </li>
                                                    </router-link>
                                                </ul>
                                            </div>
                                        </li>
                                    </router-link>
                                    <router-link to="/archive/Office" v-slot="{ href, navigate, isActive, isExactActive }">
                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                            <span>
                                                <a :href="href" @click="navigate">
                                                    Office
                                                </a>
                                                <router-link to="/archive" class="primary-nav__back"></router-link>
                                            </span>
                                            <div class="primary-nav__child">
                                                <ul>
                                                    <router-link to="/archive/Office" v-slot="{ href, navigate, isActive, isExactActive }">
                                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                                            <span>
                                                                <a :href="href" @click="navigate">
                                                                    <span class="ellip" v-if="board">{{ board.subject }}</span>
                                                                </a>
                                                                <router-link to="/archive/Office" class="primary-nav__back"></router-link>
                                                            </span>
                                                        </li>
                                                    </router-link>
                                                </ul>
                                            </div>
                                        </li>
                                    </router-link>
                                    <router-link to="/archive/Etc" v-slot="{ href, navigate, isActive, isExactActive }">
                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                            <span>
                                                <a :href="href" @click="navigate">
                                                    Etc
                                                </a>
                                                <router-link to="/archive" class="primary-nav__back"></router-link>
                                            </span>
                                            <div class="primary-nav__child">
                                                <ul>
                                                    <router-link to="/archive/Etc" v-slot="{ href, navigate, isActive, isExactActive }">
                                                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                                                            <span>
                                                                <a :href="href" @click="navigate">
                                                                    <span class="ellip" v-if="board">{{ board.subject }}</span>
                                                                </a>
                                                                <router-link to="/archive/Etc" class="primary-nav__back"></router-link>
                                                            </span>
                                                        </li>
                                                    </router-link>
                                                </ul>
                                            </div>
                                        </li>
                                    </router-link>
                                </ul>
                            </div>
                        </li>
                    </router-link>
                    <router-link to="/contact" v-slot="{ href, navigate, isActive, isExactActive }">
                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                            <span>
                                <a :href="href" @click="navigate">
                                    Contact
                                </a>
                                <router-link to="/" class="primary-nav__back"></router-link>
                            </span>
                        </li>
                    </router-link>
                    <!-- <router-link to="/board" v-slot="{ href, navigate, isActive, isExactActive }">
                        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                            <span>
                                <a :href="href" @click="navigate">
                                    Board
                                </a>
                                <router-link to="/" class="primary-nav__back"></router-link>
                            </span>
                        </li>
                    </router-link> -->
                </ul>
            </nav>
            <nav id="secondary-nav">
                <div><strong>주소</strong>서울시 강남구 논현로 102길 58 5층 디자인비담</div>
                <div><strong>대표이메일</strong>bidam_design@naver.com</div>
                <div><strong>대표전화</strong>010.3696.7765</div>
                <div><strong>대표팩스</strong>02.2135.6808</div>
            </nav>
        </div>
    </header>
</template>

<script>
import { Header } from "@/plugins/header.js"; // Heaer 스크립트
import gnbLoginButton from "@/components/client/login/gnb-login-button.vue";
import LocaleSelect from "@/components/client/inc/locale-select.vue";

export default {
    components: {
        gnbLoginButton,
        LocaleSelect
    },
    mounted() {
        this.header();
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
    },
    methods: {
        header: function() {
            this.$nextTick(function() {
                Header(); // Heaer 스크립트 로드
            });
        },
    },
    computed: {
        board(){
            return this.$store.state.board;
        }
    }
};
</script>
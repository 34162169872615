<template>
    <div class="wrap wrap--join">
        <!-- 헤더 -->
        <main-header></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <div class="inner">
                <h2 class="join-title">회원가입</h2>

                <div class="join-container">
                    <div class="join-gate">
                        <!-- 개인 -->
                        <div class="join-gate-box join-gate-box--personal">
                            <div class="join-gate-box__inner">
                                <h2>일반회원</h2>
                                <p>
                                    회원에 가입하시면 신작소개 및 이벤트 정보를 받아볼수 있습니다.
                                </p>
                                <i class="icon icon-normal"></i>
                                <v-btn
                                    @click="submit('normal')"
                                    color="primary"
                                >
                                    가입하기
                                </v-btn>
                            </div>
                        </div>
                        <!-- 업체 -->
                        <div class="join-gate-box join-gate-box--business">
                            <div class="join-gate-box__inner">
                                <h2>아티스트회원</h2>
                                <p>
                                    작품을 민팅하신 작가님만 회원가입이 가능합니다.
                                </p>
                                <i class="icon icon-artist"></i>
                                <v-btn
                                    @click="submit('author')"
                                    color="primary"
                                >
                                    가입하기
                                </v-btn>
                            </div>
                        </div>
                    </div>

                    <!-- 하단버튼 -->
                    <!-- <div class="bottom-button">
                        <a class="button button--border-primary" href="" onclick="return false;" @click="$router.go(-1)"><span>취소하기</span></a>
                        <a class="button button--primary" @click="submit"><span>계속 진행</span></a>
                    </div> -->
                </div>
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";
export default {
    components : {
        MainHeader,
        MainFooter
    },
    methods: {
        submit(code){
            this.$router.push(`/join?code=${code}`);
        }
    }
}
</script>

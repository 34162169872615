var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h1', [_vm._v("DESIGN BIDAM")]), _c('router-link', {
    attrs: {
      "id": "fluid-title",
      "to": "/"
    }
  }, [_c('div', {
    staticClass: "letters",
    on: {
      "click": function ($event) {
        _vm.$route.path == '/' ? _vm.$emit('intro') : null;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/logo.svg",
      "alt": "DESIGN BIDAM"
    }
  })])]), _c('div', {
    staticClass: "visible",
    attrs: {
      "id": "menu"
    }
  }, [_c('nav', {
    attrs: {
      "id": "primary-nav"
    }
  }, [_c('ul', [_c('router-link', {
    attrs: {
      "to": "/about"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var href = _ref.href,
          navigate = _ref.navigate,
          isActive = _ref.isActive,
          isExactActive = _ref.isExactActive;
        return [_c('li', {
          class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
        }, [_c('span', [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" About ")]), _c('router-link', {
          staticClass: "primary-nav__back",
          attrs: {
            "to": "/"
          }
        })], 1)])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/archive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var href = _ref2.href,
          navigate = _ref2.navigate,
          isActive = _ref2.isActive,
          isExactActive = _ref2.isExactActive;
        return [_c('li', {
          class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
        }, [_c('span', [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" Archive ")]), _c('router-link', {
          staticClass: "primary-nav__back",
          attrs: {
            "to": "/"
          }
        })], 1), _c('div', {
          staticClass: "primary-nav__child"
        }, [_c('ul', [_c('router-link', {
          attrs: {
            "to": "/archive/Residence"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var href = _ref3.href,
                navigate = _ref3.navigate,
                isActive = _ref3.isActive,
                isExactActive = _ref3.isExactActive;
              return [_c('li', {
                class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
              }, [_c('span', [_c('a', {
                attrs: {
                  "href": href
                },
                on: {
                  "click": navigate
                }
              }, [_vm._v(" Residence ")]), _c('router-link', {
                staticClass: "primary-nav__back",
                attrs: {
                  "to": "/archive"
                }
              })], 1), _c('div', {
                staticClass: "primary-nav__child"
              }, [_c('ul', [_c('router-link', {
                attrs: {
                  "to": "/archive/Residence"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref4) {
                    var href = _ref4.href,
                      navigate = _ref4.navigate,
                      isActive = _ref4.isActive,
                      isExactActive = _ref4.isExactActive;
                    return [_c('li', {
                      class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
                    }, [_c('span', [_c('a', {
                      attrs: {
                        "href": href
                      },
                      on: {
                        "click": navigate
                      }
                    }, [_vm.board ? _c('span', {
                      staticClass: "ellip"
                    }, [_vm._v(_vm._s(_vm.board.subject))]) : _vm._e()]), _c('router-link', {
                      staticClass: "primary-nav__back",
                      attrs: {
                        "to": "/archive/Residence"
                      }
                    })], 1)])];
                  }
                }], null, true)
              })], 1)])])];
            }
          }], null, true)
        }), _c('router-link', {
          attrs: {
            "to": "/archive/Retail"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var href = _ref5.href,
                navigate = _ref5.navigate,
                isActive = _ref5.isActive,
                isExactActive = _ref5.isExactActive;
              return [_c('li', {
                class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
              }, [_c('span', [_c('a', {
                attrs: {
                  "href": href
                },
                on: {
                  "click": navigate
                }
              }, [_vm._v(" Retail ")]), _c('router-link', {
                staticClass: "primary-nav__back",
                attrs: {
                  "to": "/archive"
                }
              })], 1), _c('div', {
                staticClass: "primary-nav__child"
              }, [_c('ul', [_c('router-link', {
                attrs: {
                  "to": "/archive/Retail"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref6) {
                    var href = _ref6.href,
                      navigate = _ref6.navigate,
                      isActive = _ref6.isActive,
                      isExactActive = _ref6.isExactActive;
                    return [_c('li', {
                      class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
                    }, [_c('span', [_c('a', {
                      attrs: {
                        "href": href
                      },
                      on: {
                        "click": navigate
                      }
                    }, [_vm.board ? _c('span', {
                      staticClass: "ellip"
                    }, [_vm._v(_vm._s(_vm.board.subject))]) : _vm._e()]), _c('router-link', {
                      staticClass: "primary-nav__back",
                      attrs: {
                        "to": "/archive/Retail"
                      }
                    })], 1)])];
                  }
                }], null, true)
              })], 1)])])];
            }
          }], null, true)
        }), _c('router-link', {
          attrs: {
            "to": "/archive/Office"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref7) {
              var href = _ref7.href,
                navigate = _ref7.navigate,
                isActive = _ref7.isActive,
                isExactActive = _ref7.isExactActive;
              return [_c('li', {
                class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
              }, [_c('span', [_c('a', {
                attrs: {
                  "href": href
                },
                on: {
                  "click": navigate
                }
              }, [_vm._v(" Office ")]), _c('router-link', {
                staticClass: "primary-nav__back",
                attrs: {
                  "to": "/archive"
                }
              })], 1), _c('div', {
                staticClass: "primary-nav__child"
              }, [_c('ul', [_c('router-link', {
                attrs: {
                  "to": "/archive/Office"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref8) {
                    var href = _ref8.href,
                      navigate = _ref8.navigate,
                      isActive = _ref8.isActive,
                      isExactActive = _ref8.isExactActive;
                    return [_c('li', {
                      class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
                    }, [_c('span', [_c('a', {
                      attrs: {
                        "href": href
                      },
                      on: {
                        "click": navigate
                      }
                    }, [_vm.board ? _c('span', {
                      staticClass: "ellip"
                    }, [_vm._v(_vm._s(_vm.board.subject))]) : _vm._e()]), _c('router-link', {
                      staticClass: "primary-nav__back",
                      attrs: {
                        "to": "/archive/Office"
                      }
                    })], 1)])];
                  }
                }], null, true)
              })], 1)])])];
            }
          }], null, true)
        }), _c('router-link', {
          attrs: {
            "to": "/archive/Etc"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref9) {
              var href = _ref9.href,
                navigate = _ref9.navigate,
                isActive = _ref9.isActive,
                isExactActive = _ref9.isExactActive;
              return [_c('li', {
                class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
              }, [_c('span', [_c('a', {
                attrs: {
                  "href": href
                },
                on: {
                  "click": navigate
                }
              }, [_vm._v(" Etc ")]), _c('router-link', {
                staticClass: "primary-nav__back",
                attrs: {
                  "to": "/archive"
                }
              })], 1), _c('div', {
                staticClass: "primary-nav__child"
              }, [_c('ul', [_c('router-link', {
                attrs: {
                  "to": "/archive/Etc"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref10) {
                    var href = _ref10.href,
                      navigate = _ref10.navigate,
                      isActive = _ref10.isActive,
                      isExactActive = _ref10.isExactActive;
                    return [_c('li', {
                      class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
                    }, [_c('span', [_c('a', {
                      attrs: {
                        "href": href
                      },
                      on: {
                        "click": navigate
                      }
                    }, [_vm.board ? _c('span', {
                      staticClass: "ellip"
                    }, [_vm._v(_vm._s(_vm.board.subject))]) : _vm._e()]), _c('router-link', {
                      staticClass: "primary-nav__back",
                      attrs: {
                        "to": "/archive/Etc"
                      }
                    })], 1)])];
                  }
                }], null, true)
              })], 1)])])];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": "/contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var href = _ref11.href,
          navigate = _ref11.navigate,
          isActive = _ref11.isActive,
          isExactActive = _ref11.isExactActive;
        return [_c('li', {
          class: [isActive && 'router-link-active', isExactActive && 'router-link-exact-active']
        }, [_c('span', [_c('a', {
          attrs: {
            "href": href
          },
          on: {
            "click": navigate
          }
        }, [_vm._v(" Contact ")]), _c('router-link', {
          staticClass: "primary-nav__back",
          attrs: {
            "to": "/"
          }
        })], 1)])];
      }
    }])
  })], 1)]), _vm._m(0)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    attrs: {
      "id": "secondary-nav"
    }
  }, [_c('div', [_c('strong', [_vm._v("주소")]), _vm._v("서울시 강남구 논현로 102길 58 5층 디자인비담")]), _c('div', [_c('strong', [_vm._v("대표이메일")]), _vm._v("bidam_design@naver.com")]), _c('div', [_c('strong', [_vm._v("대표전화")]), _vm._v("010.3696.7765")]), _c('div', [_c('strong', [_vm._v("대표팩스")]), _vm._v("02.2135.6808")])]);

}]

export { render, staticRenderFns }